<template>
  <div class="home-page">
    <section id="home" class="hero-banner">
      <figure>
        <img class="w100" src="../assets/hero-banner.jpg" alt="murals of youth" />
      </figure>
      <h1 v-html="c.mainTitle" class="uppercase text-center"></h1>
      <p v-html="c.subTitle" class="text-center"></p>
    </section>

<!--    <section id="video" class="video-clip">-->
<!--      <iframe width="100%"-->
<!--              height="315"-->
<!--              src="https://www.youtube.com/embed/BnHKj2SQN9w?si=en3p_P1iMdsouo8H"-->
<!--              title="YouTube video player"-->
<!--              frameborder="0"-->
<!--              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen/>-->
<!--    </section>-->

    <section id="welcome" class="welcome m-t-24">
      <figure class="mauto">
        <img class="w100"
             src="../assets/logo.svg" alt="murals of youth" />
      </figure>

      <p v-html="c.welcomeSection.description" />

      <p v-html="c.welcomeSection.unleashCreativity" class="text-center m-b-24" />

      <p class="text-center">
        <a :href="activeLanguage === 'ro' ? 'Regulament-de-Participare-Murals-of-Youth-2024-EN.pdf' : 'Regulament-de-Participare-Murals-of-Youth-2024-RO.pdf'"
           :download="activeLanguage === 'ro' ? 'reguli-de-participare' : 'rules-of-participation'">
          {{ c.welcomeSection.rulesOfParticipation }}
        </a>
      </p>

<!--      <a href="https://my.c156.ro"-->
<!--         target="_blank"-->
<!--         class="block no-border bg-white mauto">-->
<!--        <img class="w100"-->
<!--             src="../assets/complete-now.svg"-->
<!--             alt="register to murals of youth festival" />-->
<!--      </a>-->

    </section>

    <section class="artworks-2023 text-center m-t-48">
      <h2 v-html="c.artworksSection.title" />
      <carousel :items-to-show="1.5">
        <template #addons>
          <navigation />
          <p v-html="c.artworksSection.hashtag" class="m0" />
        </template>
        <slide v-for="slide in 33"
               :key="slide"
               @click="openPhotoSwipe(slide - 1)">
          <img :src="require(`../assets/pics/${slide}.jpg`)" :alt="`Murals of youth ${slide}`">
        </slide>
      </carousel>

      <div class="my-gallery">
        <template v-for="slide in 33" :key="slide">
          <a :href="require(`../assets/pics/${slide}.jpg`)" :data-pswp-width="getImageWidth(slide)" :data-pswp-height="400">
            <img :src="require(`../assets/pics/${slide}.jpg`)" :alt="'Slide ' + slide" />
          </a>
        </template>
      </div>
    </section>

    <section id="about-us" class="about-us m-t-48">
      <img src="../assets/about-us.svg" alt="about murals of youth" />

      <h2 v-html="c.aboutSection.introduction" class="m-t-16" />

      <p v-html="c.aboutSection.details" />
    </section>

    <section id="schedule" class="schedule relative m-t-48">
      <figure class="absolute">
        <img src="../assets/schedule-form-bg.svg" alt="murals of youth" />
      </figure>

      <img src="../assets/schedule-icon.svg" alt="murals of youth schedule" />

      <p v-html="c.scheduleSection.description" />

      <div class="m-t-16">
        <ToggleList :items="c.scheduleSection.days">
          <template #header="{ item, index }">
            <p class="flex flex-between">
              <span>DAY {{ index + 1 }}</span>
              <span>{{ item.date }}</span>
            </p>
          </template>
          <template #content="{ item }">
            <ul v-if="item.events.length" class="bg-white">
              <li v-for="(event, index) in item.events" :key="index">
                <h4 class="flex flex-between">
                  <span>{{ event.title }}</span>
                  <span>{{ event.time }}</span>
                </h4>
                <p>{{ event.text }}</p>
              </li>
            </ul>
          </template>
        </ToggleList>
      </div>

      <figure class="absolute">
        <img src="../assets/schedule-form-bg.svg" alt="murals of youth" />
      </figure>
    </section>

    <section id="gallery">
      <figure class="text-center">
        <img src="../assets/gallery-icon.svg" alt="murals of youth 2023 gallery" />
      </figure>

      <div class="type-tabs flex flex-center m-t-24">
        <button @click="showPhotos = true" :class="{ active: showPhotos }">PHOTOS</button>
        <button @click="showPhotos = false" :class="{ active: !showPhotos, videos: true }">VIDEOS</button>
      </div>

      <carousel :items-to-show="1.5" v-if="showPhotos">
        <template #addons>
          <navigation />
        </template>
        <slide v-for="slide in 33"
               :key="slide"
               @click="openPhotoSwipe(slide - 1)">
            <img :src="require(`../assets/pics/${slide}.jpg`)" alt="">
        </slide>
      </carousel>

      <carousel :items-to-show="1.5" v-if="!showPhotos">
        <template #addons>
          <navigation />
        </template>
        <slide v-for="(video, index) in videos" :key="index" @click="openVideo(index)">
            <img :src="getThumbnailUrl(video.id)" alt="">
        </slide>
      </carousel>

      <div class="my-gallery" v-if="showPhotos">
        <template v-for="slide in 33" :key="slide">
          <a :href="require(`../assets/pics/${slide}.jpg`)" :data-pswp-width="getImageWidth(slide)" :data-pswp-height="400">
            <img :src="require(`../assets/pics/${slide}.jpg`)" :alt="'Slide ' + slide" />
          </a>
        </template>
      </div>

      <div v-if="showVideoModal" class="video-modal" @click="closeVideo">
        <div class="video-container">
          <iframe :src="currentVideoSrc" frameborder="0" allowfullscreen></iframe>
        </div>
      </div>

      <p v-html="c.gallerySection.description" />

      <a v-html="c.gallerySection.viewMurals" class="block text-center color-red" href="#" />
    </section>

    <section id="sponsors">
      <figure class="text-center">
        <img src="../assets/sponsors-icon.svg" alt="murals of youth sponsors" />
      </figure>

      <p>
        Nullam hendrerit ante purus, sit amet euismod ex placerat vitae. Proin ut tellus eget magna suscipit lacinia.
      </p>

      <figure class="flex">
        <img src="" alt="sponsor 1">
        <img src="" alt="sponsor 2">
        <img src="" alt="sponsor 3">
      </figure>
    </section>

    <section id="faq" class="faq relative">
      <figure class="absolute">
        <img src="../assets/faq-shape-bg.svg" alt="murals of youth" />
      </figure>

      <img src="../assets/faq.svg" alt="murals of youth schedule" />

      <div>
        <ToggleList :items="c.faqSection.faq">
          <template #header="{ item }">
            <p class="flex flex-between">{{ item.q }}</p>
          </template>
          <template #content="{ item }">
            <p class="answer">{{ item.a }}</p>
          </template>
        </ToggleList>
      </div>

      <figure class="absolute">
        <img src="../assets/faq-shape-bg.svg" alt="murals of youth" />
      </figure>
    </section>

    <section id="contact">
      <figure>
        <img src="../assets/contact-icon.svg" alt="contact murals of youth" />
      </figure>

      <p v-html="c.contactSection.description" />

      <div v-if="!submitted">
        <form @submit.prevent="handleSubmit">
          <label for="name">
            <span v-html="c.contactSection.nameLabel" /> <br>
            <input id="name" type="text"
                   :value="form.name"
                   @input="updateField('name', $event.target.value)"
                   placeholder="your name" />
          </label>
          <label for="email">
            <span v-html="c.contactSection.emailLabel" /> <br>
            <input id="email" type="text"
                   :value="form.from"
                   @input="updateField('from', $event.target.value)"
                   :class="{ 'input-error': errors.email }"
                   placeholder="your email" />
          </label>
          <label for="msg">
            <span v-html="c.contactSection.messageLabel" /> <br>
            <textarea id="msg"
                      :value="form.message"
                      @input="updateField('message', $event.target.value)"
                      placeholder="your message" />
          </label>

          <div class="text-right">
            <button>
              <img src="../assets/send-icon.svg" alt="contact murals of youth" />
            </button>
          </div>
        </form>
      </div>

      <div v-else>
        <p class="thank-you" v-html="c.contactSection.thankyouMessage" />
      </div>
    </section>
  </div>
</template>

<script setup>
  import ToggleList from '../components/ToggleList.vue';
  import 'vue3-carousel/dist/carousel.css'
  import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
  import {computed, onMounted, reactive, ref} from "vue";
  import PhotoSwipeLightbox from "photoswipe/lightbox";
  import 'photoswipe/style.css';
  import { useStore } from "vuex";
  import { validateEmail } from '@/store/utils';

  const { state, dispatch, getters, commit } = useStore();

  const c = computed(() => getters.content)
  const activeLanguage = computed(() => state.activeLanguage)

  const imageDimensions = ref(Array.from({ length: 33 }, () => ({ width: 600, height: 400 })));
  const getImageWidth = (index) => imageDimensions.value[index - 1]?.width || 600;

  const preloadImages = () => {
    for (let i = 0; i < 33; i++) {
      const img = new Image();
      img.src = require(`../assets/pics/${i + 1}.jpg`);

      img.onload = () => {
        const aspectRatio = img.width / img.height;
        imageDimensions.value[i] = {
          width: aspectRatio * 400,
          height: 400,
        };
      };
    }
  };

  onMounted(preloadImages);

  const showPhotos = ref(true);
  const showVideoModal = ref(false);
  const currentVideoSrc = ref('');

  const videos = ref([
    { id: 'LsxwLZI-wx0' },
    { id: 'spmdql5GZFc' },
    { id: 'BnHKj2SQN9w' },
  ]);

  const openPhotoSwipe = (index) => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.my-gallery',
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });

    lightbox.init();
    lightbox.loadAndOpen(index);
  };

  onMounted(() => {
    const lightbox = new PhotoSwipeLightbox({
      gallery: '.my-gallery',
      children: 'a',
      pswpModule: () => import('photoswipe'),
    });

    lightbox.init();
  });

  function openVideo(index) {
    currentVideoSrc.value = `https://www.youtube.com/embed/${videos.value[index].id}`;
    showVideoModal.value = true;
  }

  function closeVideo() {
    showVideoModal.value = false;
  }

  function getThumbnailUrl(videoId) {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  }

  const form = computed(() => state.contact.form);
  const errors = reactive({ email: false, msg: false });
  const submitted = ref(false);

  const updateField = (name, value) => {
    commit('SET_FORM_FIELD', { name, value });
  };

  const validateForm = () => {
    errors.email = !validateEmail(form.value.from);
    errors.msg = !form.value.message;
    return !errors.email && !errors.msg;
  };

  const handleSubmit = async () => {
    if (!validateForm()) {
      return;
    }
    try {
      await dispatch('submitContactForm', form.value);
      submitted.value = true;
      commit('RESET_FORM');
    } catch (error) {
    }
  };
</script>

<style lang="scss">
  @import "../css/vars";
  .home-page {
    @media only screen and (min-width: 0) {
      padding: 45px 0 0;
      .input-error {
        border: 1px solid red;
      }

      .my-gallery {
        display: none;
        .pswp__button--zoom {
          display: none !important;
        }
      }

      .hero-banner {
        h1 {
          padding: 24px 24px 0;
          font-size: 3rem;
        }
        p {
          margin-top: 4px;
        }
      }

      .welcome {
        figure {
          width: 130px;
        }
        p {
          padding: 10px 24px;
          font-size: 2rem;
          span {
            color: $red;
          }
        }
        a {
          width: 200px;
        }
      }

      .about-us {
        margin-top: 50px;
        padding: 0 24px;
        h2 {
          font-size: 2.2rem;
        }
        img {
          width: 120px;
        }
      }

      .artworks-2023 {
        margin-top: 50px;
        .carousel {
          margin: 25px 0;
          > button {
            width: 25px;
            height: 25px;
            transform: translateY(30px) scale(1.4);
            color: white;
            margin: 0 15px;
            background-color: black;
            border-radius: 50%;
            border: 1px solid white;
          }
          li {
            width: fit-content !important;
          }
          img {
            max-width: 100%;
            margin-right: 30px;
            max-height: 220px;
            cursor: pointer;
          }
        }
      }

      .schedule {
        margin: 70px auto;
        min-height: 200px;
        padding: 8px 16px;
        background-color: $dark-green;
        overflow-x: clip;
        p {
          color: white;
          margin-bottom: 30px;
        }
        > div {
          ul {
            li {
              padding: 12px;
              margin-bottom: 8px;
              background-color: $red;
            }
            p {
              margin: 0;
              color: white;
            }
            ul {
              margin-top: 12px;
              padding: 16px;
              li {
                background-color: white;
              }
              h4 {
                padding-bottom: 4px;
                border-bottom: 1px solid black;
                span {
                  font-size: 1.6rem;
                }
              }
              p {
                color: black;
                font-size: 1.6rem;
              }
            }
          }
        }
        figure {
          height: 40px;
          width: 110%;
          left: -20px;
          overflow: hidden;
          z-index: -1;
          img {
            width: 100%;
          }
          &:first-child {
            top: -20px;
          }
          &:last-child {
            bottom: -20px;
            img {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }

      #gallery {
        margin-top: 44px;

        .type-tabs button {
          cursor: pointer;
          font-size: 1.6rem;
          margin-right: 15px;
          color: black;

          &.active {
            text-decoration: underline;
          }
        }

        p {
          padding: 0 24px;
        }

        .carousel{
          margin: 0 0 48px;
          > button {
            width: 25px;
            height: 25px;
            transform: translateY(30px) scale(1.4);
            color: white;
            margin: 0 15px;
            background-color: black;
            border-radius: 50%;
            border: 1px solid white;
          }
          li {
            width: fit-content !important;
          }
          img {
            max-width: 100%;
            margin-right: 30px;
            max-height: 220px;
            cursor: pointer;
          }
        }

        .video-modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 1000;
        }

        .video-container {
          display: flex;
          align-items: center;
          position: relative;
          width: 90%;
          max-width: 700px;
          padding-top: 56.25%;
          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 400px;
            border: none;
          }
        }
      }

      #sponsors {
        display: none;
        margin-top: 55px;
        padding: 0 16px;
        p {
          margin-top: 15px;
        }
      }

      .faq {
        margin-top: 80px;
        padding: 8px 16px;
        background-color: $red;
        overflow-x: clip;
        > div {
          margin-top: 8px;
          ul {
            li {
              padding: 8px;
              margin-bottom: 8px;
              background-color: $dark-green;
            }
            p {
              margin: 0;
              color: white;
            }
            p.answer {
              margin-top: 10px;
              padding: 8px 12px;
              background-color: white;
              color: $dark-green;
            }
          }
        }
        figure {
          height: 40px;
          width: 110%;
          left: -20px;
          overflow: hidden;
          z-index: -1;
          img {
            width: 100%;
          }
          &:first-child {
            top: -20px;
          }
          &:last-child {
            bottom: -20px;
            img {
              position: absolute;
              bottom: 0;
            }
          }
        }
      }

      #contact {
        margin-top: 60px;
        padding: 24px;
        p {
          margin-top: 12px;
        }
        form {
          label {
            margin-bottom: 6px;
            display: block;
            span {
              font-weight: 300;
            }
            input, textarea {
              width: 100%;
              padding: 4px 0 4px 8px;
              margin-top: 4px;
              border: 2px solid black;
              &::placeholder {
                font-weight: 300;
              }
            }
            textarea {
              height: 120px;
            }
          }
        }
        .text-right button {
          cursor: pointer;
        }
        .thank-you {
          color: #028802;
        }
      }
    }

    @media only screen and (min-width: 800px) {
      padding: 55px 0 0;
      #gallery {
        .carousel {
          > button {
            transform: translateY(80px) scale(1.4);
            color: white;
          }
          img {
            max-height: 300px;
          }
        }
      }
      .artworks-2023 {
        .carousel {
          > button {
            transform: translateY(80px) scale(1.4);
            color: white;
          }
          img {
            max-height: 300px;
          }
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      padding: 70px 0 0;
      section {
        max-width: 800px;
        margin: 0 auto;
      }
      #gallery {
        .video-container {
          height: 50%;
          padding-top: 0;
        }
      }
    }
  }
</style>
