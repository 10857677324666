<template>
  <header class="main-header flex flex-between align-center fixed left0 top0 w100 z99">
    <img class="logo" src="../assets/logo.svg" alt="murals of youth logo" />

    <i v-if="isMobile" @click="toggleMobileMenu">
      <svg v-if="!isMobileMenuOpen" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 12H17C17.55 12 18 11.55 18 11C18 10.45 17.55 10 17 10H1C0.45 10 0 10.45 0 11C0 11.55 0.45 12 1 12ZM1 7H17C17.55 7 18 6.55 18 6C18 5.45 17.55 5 17 5H1C0.45 5 0 5.45 0 6C0 6.55 0.45 7 1 7ZM0 1C0 1.55 0.45 2 1 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H1C0.45 0 0 0.45 0 1Z" fill="#202530"/>
      </svg>
      <svg v-else width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 1L17 17M17 1L1 17" stroke="#202530" stroke-width="2"/>
      </svg>
    </i>

    <div v-if="isMobile && isMobileMenuOpen" class="mobile-menu">
      <ul>
        <li v-for="page in c.navigationHeader.pages" :key="page.id" @click="scrollToSection(page.id)">
          {{ page.title }}
        </li>
      </ul>
      <div class="language-buttons">
        <button @click="setLanguage('en')"><img class="icon-language" src="../assets/icon_en.png" alt="Murals of youth"></button>
        <button @click="setLanguage('ro')"><img class="icon-language"  src="../assets/icon_ro.png" alt="Concurs arta"></button>
      </div>
    </div>

    <ul v-if="!isMobile" class="desktop-menu">
      <li v-for="page in c.navigationHeader.pages" :key="page.id">
        <a :href="'#' + page.id" @click.prevent="scrollToSection(page.id)">{{ page.title }}</a>
      </li>
      <div class="language-buttons">
        <button @click="setLanguage('en')"><img class="icon-language" src="../assets/icon_en.png" alt="Murals of youth"></button>
        <button @click="setLanguage('ro')"><img class="icon-language"  src="../assets/icon_ro.png" alt="Concurs arta"></button>
      </div>
    </ul>
  </header>
</template>

<script setup>
  import { ref, inject, onMounted, onUnmounted, computed } from 'vue';
  import { useStore } from "vuex";
  const { getters, dispatch } = useStore();

  const c = computed(() => getters.content)

  const setLanguage = (language) => {
    dispatch('changeLanguage', language);
  };

  const isMobile = ref(true);
  const isMobileMenuOpen = ref(false);

  function toggleMobileMenu() {
    isMobileMenuOpen.value = !isMobileMenuOpen.value;
  }

  const updateIsMobile = () => {
    isMobile.value = window.innerWidth <= 767;
  };

  onMounted(() => {
    updateIsMobile();
    window.addEventListener('resize', updateIsMobile);
  });

  onUnmounted(() => {
    window.removeEventListener('resize', updateIsMobile);
  });

  const smoothScroll = inject('smoothScroll')

  function scrollToSection (id) {
    const elm = document.getElementById(id);
    smoothScroll({
      scrollTo: elm,
      offset: -60
    })
    isMobileMenuOpen.value = false;
  }
</script>

<style lang="scss">
  .main-header {
    @media only screen and (min-width: 0) {
      padding: 8px 16px;
      background-color: white;
      box-shadow: 5px 2px 5px rgba(219, 87, 89, 0.99);

      .desktop-menu {
        display: none;
      }

      i {
        z-index: 2;
        cursor: pointer;
      }

      .logo {
        z-index: 2;
      }

      .icon-language {
        height: 25px;
        width: 25px;
        margin-left: 10px;
        cursor: pointer;
      }

      .mobile-menu {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        text-align: center;

        li {
          margin-bottom: 10px;
          cursor: pointer;
        }

        .language-buttons {
          margin-top: 40px;
        }
      }
    }

    @media only screen and (min-width: 768px) {
      padding: 13px 16px;

      .logo {
        transform: scale(1.3);
      }

      .mobile-menu {
        display: none;
      }

      .desktop-menu {
        display: flex;
        z-index: 1;

        a {
          text-decoration: none;
          color: black;
          margin-left: 20px;
        }

        .language-buttons {
          margin-left: 60px;
        }
      }
    }

    @media only screen and (min-width: 1024px) {
      padding: 19px;

      .logo {
        transform: scale(1.5);
      }
    }
  }
</style>